import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";

const URL = BASE_URL + "/article";

export const addArticle = (form) => {
  return axiosInstanceCollab.post(`${URL}`, form);
};

export const getPartialArticle = (offset) => {
  return axiosInstanceCollab.get(`${URL}/${offset}`);
};

export const getAllArticle = () => {
  return axiosInstanceCollab.get(`${URL}`);
};

export const getArticleById = (id) => {
  return axiosInstanceCollab.get(`${BASE_URL}/articleById/${id}`);
};

export const updateArticleById = (id, data) => {
  return axiosInstanceCollab.put(`${URL}/${id}`, data);
};

export const deleteArticleById = (id) => {
  return axiosInstanceCollab.delete(`${URL}/${id}`);
};

export const switchAticleSelected = (id, data) => {
  return axiosInstanceCollab.put(`${URL}/is-selected/${id}`, data);
};

export const getMostCommentedArticle = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/most-commented-article`);
};
export const getArticleSelected = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/article-selected`);
};

export const getArticleBySlug = (slug) => {
  return axiosInstanceCollab.get(`${BASE_URL}/articleBySlug/${slug}`);
};
