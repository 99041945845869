var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:['container-fluid navbar sticky-top p-0 custom_transition', {}]},[_c('div',{class:[
      'container-fluid justify-content-between align-items-center bg-secondary p-2 d-none text-muted text-primary nav-alt',
      {
        hidden_contact: _vm.scrollPosition > 250,
      } ]},[_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"col d-flex flex-fill align-items-center"},[_c('IcoLocalisation'),_c('div',{staticClass:"ms-2"},[_vm._v("II H 63GB bis Ampandrana")])],1),_c('small',{staticClass:"col d-flex flex-fill align-items-center ps-4"},[_c('IcoTel'),_c('div',{staticClass:"ms-2"},[_vm._v("+261 34 30 390 27")])],1),_c('small',{staticClass:"col d-flex flex-fill align-items-center ps-4"},[_c('IcoMail'),_c('div',{staticClass:"ms-2"},[_vm._v("mk.consult.customer@gmail.com")])],1)]),_c('small',{staticClass:"d-flex text-end"},[_c('div',{staticClass:"col ms-3",attrs:{"href":"https://web.facebook.com/MKCOPartner","target":"_blank","rel":"noopener noreferrer"}},[_c('IcoFacebook')],1),_c('div',{staticClass:"col ms-3"},[_c('IcoInstagram')],1),_c('div',{staticClass:"col ms-3"},[_c('IcoWhatsapp')],1),_c('div',{staticClass:"col ms-3",attrs:{"href":"https://www.linkedin.com/company/mk-co-partner","target":"_blank","rel":"noopener noreferrer"}},[_c('IcoLinkedin')],1)])]),_c('nav',{class:[
      'navbar navbar-expand-lg navbar-dark bg-dark container-fluid custom_transition',
      {
        change_color: _vm.scrollPosition > 100,
      } ]},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navBarMK-CO"}},[_c('ul',{staticClass:"container-fluid navbar-nav text-lg-center d-flex align-items-lg-center align-items-end justify-content-evenly"},[_vm._m(2),_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/"}},[_vm._v("Découvrir")])],1),_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":("/accompagnement/" + (_vm.services[0] && _vm.services[0].slug))}},[_vm._v("Accompagnement")])],1),_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/offre-emploi"}},[_vm._v("Talents")])],1),_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/articles"}},[_vm._v("S'informer")])],1),(!_vm.isLoggedIn)?_c('li',{staticClass:"nav-item text-lg-center"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/se-connecter"}},[_vm._v("Mon espace")])],1):_vm._e(),(_vm.isLoggedIn)?_c('li',{staticClass:"nav-item text-lg-center"},[_c('div',{staticClass:"dropdown text-light d-flex"},[_c('div',{staticClass:"d-flex align-items-center",attrs:{"data-bs-toggle":"dropdown","aria-expanded":"false","role":"button"}},[_c('span',{staticClass:"me-3"},[_vm._v(" "+_vm._s(_vm.me.first_name_visit)+" ")])]),_c('Notification'),_c('ul',{staticClass:"dropdown-menu dropdown-menu-dark"},[_c('li',[_c('a',{staticClass:"dropdown-item cursor-pointer",on:{"click":_vm.redirectToProfil}},[_vm._v("Mon espace")])]),_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.disconnect}},[_vm._v("Se déconnecter")])])])],1)]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-brand d-lg-none d-block",attrs:{"href":"/"}},[_c('img',{staticClass:"logo-MK-CO animation-show",attrs:{"src":require("../../assets/logo/MK-CO-2-W.svg"),"width":"50"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navBarMK-CO","aria-controls":"navBarMK-CO","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item text-lg-center d-none d-lg-block"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"logo-MK-CO animation-show",attrs:{"src":require("../../assets/logo/MK-CO-2-W.svg"),"width":"50"}})])])}]

export { render, staticRenderFns }