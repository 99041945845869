<template>
  <div>
    <TheHeader />
    <slot />
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "../components/public/TheHeader.vue";
import TheFooter from "../components/public/TheFooter.vue";

export default {
  name: "LayoutVisiteur",
  components: { TheHeader, TheFooter },
};
</script>
