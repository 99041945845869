<template>
  <header
    :class="['container-fluid navbar sticky-top p-0 custom_transition', {}]"
  >
    <div
      :class="[
        'container-fluid justify-content-between align-items-center bg-secondary p-2 d-none text-muted text-primary nav-alt',
        {
          hidden_contact: scrollPosition > 250,
        },
      ]"
    >
      <div class="d-flex">
        <small class="col d-flex flex-fill align-items-center">
          <IcoLocalisation />
          <div class="ms-2">II H 63GB bis Ampandrana</div>
        </small>
        <small class="col d-flex flex-fill align-items-center ps-4">
          <IcoTel />
          <div class="ms-2">+261 34 30 390 27</div>
        </small>
        <small class="col d-flex flex-fill align-items-center ps-4">
          <IcoMail />
          <div class="ms-2">mk.consult.customer@gmail.com</div>
        </small>
      </div>
      <small class="d-flex text-end">
        <div
          class="col ms-3"
          href="https://web.facebook.com/MKCOPartner"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IcoFacebook />
        </div>
        <div class="col ms-3"><IcoInstagram /></div>
        <div class="col ms-3"><IcoWhatsapp /></div>
        <div
          class="col ms-3"
          href="https://www.linkedin.com/company/mk-co-partner"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IcoLinkedin />
        </div>
      </small>
    </div>
    <nav
      :class="[
        'navbar navbar-expand-lg navbar-dark bg-dark container-fluid custom_transition',
        {
          change_color: scrollPosition > 100,
        },
      ]"
    >
      <a class="navbar-brand d-lg-none d-block" href="/">
        <img
          src="../../assets/logo/MK-CO-2-W.svg"
          width="50"
          class="logo-MK-CO animation-show"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navBarMK-CO"
        aria-controls="navBarMK-CO"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navBarMK-CO">
        <ul
          class="container-fluid navbar-nav text-lg-center d-flex align-items-lg-center align-items-end justify-content-evenly"
        >
          <li class="nav-item text-lg-center d-none d-lg-block">
            <a class="navbar-brand" href="/">
              <img
                src="../../assets/logo/MK-CO-2-W.svg"
                width="50"
                class="logo-MK-CO animation-show"
              />
            </a>
          </li>
          <li class="nav-item text-lg-center">
            <router-link to="/" class="nav-link">Découvrir</router-link>
          </li>
          <li class="nav-item text-lg-center">
            <!-- <router-link to="" class="nav-link tapaho"
              >Accompagnement</router-link
            > -->
            <router-link
              :to="`/accompagnement/${services[0] && services[0].slug}`"
              class="nav-link"
              >Accompagnement</router-link
            >
          </li>
          <li class="nav-item text-lg-center">
            <router-link to="/offre-emploi" class="nav-link"
              >Talents</router-link
            >
          </li>
          <li class="nav-item text-lg-center">
            <!-- <router-link to="/articles" class="nav-link tapaho"
              >S'informer</router-link
            > -->
            <router-link to="/articles" class="nav-link"
              >S'informer</router-link
            >
          </li>
          <li class="nav-item text-lg-center" v-if="!isLoggedIn">
            <router-link to="/se-connecter" class="nav-link"
              >Mon espace</router-link
            >
          </li>
          <li class="nav-item text-lg-center" v-if="isLoggedIn">
            <div class="dropdown text-light d-flex">
              <div
                class="d-flex align-items-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                role="button"
              >
                <span class="me-3">
                  {{ me.first_name_visit }}
                </span>
              </div>
              <Notification />
              <ul class="dropdown-menu dropdown-menu-dark">
                <li>
                  <a
                    class="dropdown-item cursor-pointer"
                    @click="redirectToProfil"
                    >Mon espace</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" @click="disconnect"
                    >Se déconnecter</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import IcoLocalisation from "vue-material-design-icons/MapMarker.vue";
import IcoTel from "vue-material-design-icons/Phone.vue";
import IcoMail from "vue-material-design-icons/Email.vue";
import IcoFacebook from "vue-material-design-icons/Facebook.vue";
import IcoInstagram from "vue-material-design-icons/Instagram.vue";
import IcoWhatsapp from "vue-material-design-icons/Whatsapp.vue";
import IcoLinkedin from "vue-material-design-icons/Linkedin.vue";
import { success } from "@/utils/toast";
import Notification from "../../components/public/NotificationVisit.vue";
import { getAllServicesWithoutContent } from "../../api/serviceAdmin";

export default {
  name: "TheHeader",
  components: {
    IcoLocalisation,
    IcoTel,
    IcoMail,
    IcoFacebook,
    IcoInstagram,
    IcoWhatsapp,
    IcoLinkedin,
    Notification,
  },
  data() {
    return {
      scrollPosition: null,
      services: [],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["visiteurStore/isLoggedIn"];
    },
    me() {
      return this.$store.getters["visiteurStore/me"];
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    getAllServicesWithoutContent().then((result) => {
      this.services = result.data;
    });
  },
  methods: {
    redirectToProfil() {
      this.$router.push(`/mon-espace/`);
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    disconnect() {
      localStorage.removeItem("token");
      this.$store.dispatch("visiteurStore/setVisiteur", {});
      this.$store.dispatch("visiteurStore/setDisconnected");
      this.$router.push(this.$route.query.redirect || "/");
      success("Vous êtes déconnecté");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

#navBarMK-CO a {
  color: $light;
  transition: 250ms;

  &:hover {
    color: darken($primary, $amount: 5);
  }

  &.router-link-exact-active {
    color: $primary;
  }
}

.btn-user {
  transition: 250ms;

  img {
    transition: 500ms;
  }

  &:hover {
    color: $primary;

    img {
      transform: scale(1.2);
    }
  }
}

// STYLE NOTIF DEBUT

.dropdown-w {
  min-width: 30rem;
}

.tab-link {
  transition: 250ms;

  &:hover {
    background-color: lighten($secondary, $amount: 10);
    color: $primary;
    cursor: pointer;
  }
}

.list-notif {
  transition: 250ms;
  cursor: pointer;

  h6 {
    transition: 250ms;
  }

  &:nth-child(2n) {
    background-color: lighten($secondary, $amount: 5);
  }

  &:nth-child(2n + 1) {
    background-color: lighten($secondary, $amount: 10);
  }

  &:hover {
    background-color: lighten($primary, $amount: 35);
    color: $dark !important;

    h6 {
      color: $dark !important;
    }
  }
}

.tapaho {
  color: $light !important;
}
.notif {
  position: absolute;
  right: 1rem;
  height: 100vh;
  overflow: scroll;
}

.btn-notif {
  padding: 1.15rem;
}
</style>
