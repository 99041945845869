<template>
  <div :class="{ 'bg-light': !loading }" class="p-3 border-0 text-dark rounded">
    <section v-if="loading">
      <h2
        class="container-fluid text-center mb-3 d-flex align-items-center justify-content-between"
      >
        <div class="w-100">Nos articles</div>
      </h2>

      <div>
        <vue-content-loading
          v-for="index in 7"
          :key="index"
          :width="400"
          :height="65"
        >
          <rect x="10" y="10" rx="3" ry="3" width="120" height="50" />
          <rect x="140" y="17" rx="3" ry="3" width="70" height="6" />
          <rect x="140" y="32" rx="3" ry="3" width="150" height="6" />
          <rect x="140" y="47" rx="3" ry="3" width="35" height="6" />
          <rect x="340" y="47" rx="3" ry="3" width="35" height="6" />
        </vue-content-loading>
      </div>
    </section>
    <section
      v-infinite-scroll="loadArticle"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
    >
      <h2
        v-if="!loading"
        class="container-fluid text-center mb-3 d-flex align-items-center justify-content-between"
      >
        <div class="w-100">Nos articles</div>
      </h2>
      <div
        class="card border-0 shadow"
        v-for="(article, i) in articles"
        :key="i"
      >
        <div class="row g-0" @click="goToArticle(article.slug)">
          <div class="col-4 img-fill position-relative">
            <img
              :src="URL_IMAGE + article.photo_card_article"
              class="img-fluid rounded-start position-absolute"
              alt="..."
            />
          </div>
          <!-- <div class="col-8"> -->
          <div
            class="col-8 card-body d-flex flex-column justify-content-between h-100"
          >
            <div class="d-flex flex-column">
              <h5 class="card-title text-primary">
                {{ article.title_article }}
              </h5>
              <p class="card-text mb-2 text-description">
                {{ article.description_article }}
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <small class="card-text text-muted m-0">
                {{ moment(article.date_article).format("LL") }}
              </small>
              <p class="card-text m-0">
                <small class="text-muted">Par {{ article.fname_collab }}</small>
              </p>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </section>
    <div v-if="loadingMore">
      <vue-content-loading
        v-for="index in 4"
        :key="index"
        :width="400"
        :height="65"
      >
        <rect x="10" y="10" rx="3" ry="3" width="120" height="50" />
        <rect x="140" y="17" rx="3" ry="3" width="70" height="6" />
        <rect x="140" y="32" rx="3" ry="3" width="150" height="6" />
        <rect x="140" y="47" rx="3" ry="3" width="35" height="6" />
        <rect x="340" y="47" rx="3" ry="3" width="35" height="6" />
      </vue-content-loading>
    </div>
  </div>
</template>

<script>
import { getPartialArticle } from "../../api/article.js";
import { URL_IMAGE } from "../../configs";
import VueContentLoading from "vue-content-loading";

export default {
  name: "ListeArticle",
  components: {
    VueContentLoading,
  },
  data() {
    return {
      URL_IMAGE: URL_IMAGE,
      loading: false,
      loadingMore: false,
      articles: [],
      busy: false,
    };
  },
  mounted() {
    this.loading = true;
    this.loadArticle();
  },
  methods: {
    loadArticle() {
      this.busy = true;
      this.loadingMore = true;
      getPartialArticle(this.articles.length).then((results) => {
        this.articles.push(...results.data);
        this.loading = false;
        if (!results.data.length) {
          this.busy = true;
          this.loadingMore = false;
        } else {
          this.busy = false;
          this.loadingMore = false;
        }
      });
    },
    goToArticle(slug) {
      console.log(slug);
      this.$router.push(`/select-article/${slug}`);
    },
    onChangePage(pageOfItems) {
      window.scrollTo(0, 0);
      this.pageOfItems = pageOfItems;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

section > div {
  margin-bottom: 2rem;
}

section > div:last-child {
  margin-bottom: 0;
}

.card {
  cursor: pointer;
  transition: 250ms;

  h5 {
    transition: 250ms;
  }

  &:hover {
    background-color: $primary;

    h5 {
      color: darken($light, $amount: 50%) !important;
    }
  }
}

.img-fill img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
