<template>
  <LayoutVisiteur>
    <section>
      <div class="container-fluid">
        <div class="row g-4 p-2 py-4">
          <div class="col-lg-8 col-12">
            <ListeArticle />
          </div>
          <div class="col-lg-4 col-12" v-if="!loading">
            <div class="row g-4">
              <div class="col-12">
                <div class="p-3 border-0 bg-light text-dark rounded">
                  <h2 class="text-center mb-3">Les plus commentés</h2>
                  <div>
                    <div class="list-group shadow">
                      <a
                        v-for="article in mostCommentedArticle"
                        :key="article.id_article"
                        class="list-group-item list-group-item-action list-group-item-light border-0 cursor-pointer"
                        @click="goToArticle(article.slug)"
                      >
                        <div class="d-flex w-100 justify-content-between">
                          <h6 class="mb-1">{{ article.title_article }}</h6>
                          <small class="text-muted">
                            {{ moment(article.date_article).format("LL") }}
                          </small>
                        </div>
                        <!-- <p class="mb-1">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Corrupti reiciendis at placeat commodi excepturi
                          eos.
                        </p> -->
                        <small class="text-muted"
                          >Par {{ article.fname_collab }}</small
                        >
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="col-12">
                  <div class="p-3 border-0 bg-light text-dark rounded">
                    <h2 class="text-center mb-3">Nos sélections</h2>
                    <div>
                      <div
                        class="card bg-dark text-light rounded-end border-0 my-4 cursor-pointer"
                        v-for="(article, i) in articleSelected"
                        :key="i"
                        @click="goToArticle(article.id_article)"
                      >
                        <div class="d-flex flex-column">
                          <div class="img-fill position-relative">
                            <img
                              :src="URL_IMAGE + article.photo_card_article"
                              class="img-fluid rounded-top position-absolute"
                              alt="..."
                            />
                          </div>
                          <div class="">
                            <div class="card-body">
                              <h5 class="card-title text-primary">
                                {{ article.title_article }}
                              </h5>
                              <p class="card-text text-description">
                                {{ article.description_article }}
                              </p>
                              <div class="d-flex justify-content-between">
                                <small class="card-text text-muted m-0">
                                  {{
                                    moment(article.date_article).format("LL")
                                  }}
                                </small>
                                <p class="card-text m-0">
                                  <small class="text-muted"
                                    >Par {{ article.fname_collab }}</small
                                  >
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12 mt-5" v-if="loading">
            <div class="mt-5">
              <vue-content-loading :width="400" :height="250">
                <rect x="10" y="0" rx="8" ry="8" width="370" height="60" />
                <rect x="10" y="80" rx="8" ry="8" width="370" height="60" />
                <rect x="10" y="160" rx="8" ry="8" width="370" height="60" />
              </vue-content-loading>
              <vue-content-loading :width="400" :height="400">
                <rect x="10" y="0" rx="8" ry="8" width="370" height="200" />
                <rect x="10" y="220" rx="8" ry="8" width="370" height="200" />
              </vue-content-loading>
            </div>
          </div>
        </div>
      </div>
    </section>
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import ListeArticle from "../../components/visiteur/ListeArticle.vue";
import { getArticleSelected, getMostCommentedArticle } from "../../api/article";
import { URL_IMAGE } from "../../configs";
import VueContentLoading from "vue-content-loading";

export default {
  name: "ArticleVisiteur",
  components: {
    LayoutVisiteur,
    ListeArticle,
    VueContentLoading,
  },
  data() {
    return {
      URL_IMAGE: URL_IMAGE,
      articleSelected: [],
      mostCommentedArticle: [],
      loading: true,
    };
  },
  mounted() {
    getMostCommentedArticle().then((result) => {
      this.mostCommentedArticle = result.data;
      this.loading = false;
    });
    getArticleSelected().then((result) => {
      this.articleSelected = result.data;
      this.loading = false;
    });
  },
  methods: {
    goToArticle(id) {
      this.$router.push(`/select-article/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.card {
  width: 100%;
  height: 100%;

  .img-fill {
    height: 10rem;
  }

  .img-fill img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.list-group a {
  transition: 250ms;

  &:nth-child(2n) {
    background-color: lighten($primary, $amount: 40);
  }

  &:nth-child(2n + 1) {
    background-color: lighten($primary, $amount: 35);
  }

  &:hover {
    background-color: $primary;
    color: $dark;
  }
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
